import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer"
import { Link } from "react-scroll";
import MenuHamburger from "../components/MenuHamburger"

export const HomeDE = () => {
  const [language, setLanguage] = useState("de");
  const [showMenuHamburger, setShowMenuHamburger] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setShowMobile(false);
        setShowMenuHamburger(false);
      } else {
        setShowMenuHamburger(true);
        setShowMobile(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="home-container">
      <div className="first-home-slide">
        <div className="background-photo-container">
        {!showMobile ? (
            <img alt="Tetiana-Bielikova" className="background-first-photo" loading="lazy" src="../photos/DSCF6830.webp"/>
            ) : (
            <img alt="Tetiana-Bielikova" className="background-first-photo-mobile" loading="lazy" src="../photos/DSCF6734.webp" />
          )}
        </div>
        {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} language={language}/>}
        {!showMenuHamburger && <Header language={language}/> }
        <Link href="#" to="scrollTarget" smooth={true} duration={1000} className="down-arrow-link">
        <div className="down-arrow-bio">
          <svg className="down-arrow" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
            <path d="M24 12c0-6.623-5.377-12-12-12s-12 5.377-12 12 5.377 12 12 12 12-5.377 12-12zm-1 0c0-6.071-4.929-11-11-11s-11 4.929-11 11 4.929 11 11 11 11-4.929 11-11zm-11.5 4.828l-3.763-4.608-.737.679 5 6.101 5-6.112-.753-.666-3.747 4.604v-11.826h-1v11.828z"/>
          </svg>
        </div>
        </Link>
      </div>
      <div className="second-home-slide" id="scrollTarget">
          <div className="home-bio-photo-container">
            <img alt="Tetiana-Bielikova" className="main-first-photo" loading="lazy" src="../photos/DSCF6974.webp"/>
          </div>
          <div className="home-bio-text-container">
              <h2 className="home-biography">Biografie</h2>
              <p className="small-bio-home">
              Ihre Leidenschaft für die Musik entwickelte sich früh, und im Alter von fünf Jahren begann sie ihre musikalische Ausbildung unter der Anleitung von Kseniia Holubieva...
              </p>
              <a role="link" aria-label="Tetiana's Bielikovas Biography" href="/de/bio" className="biography-button-more"><span className="white-font">Mehr lesen</span></a>
          </div>
          <div className="home-bio-photo-container-mobile">
            <img alt="Tetiana-Bielikova" className="main-first-photo-mobile" src="../photos/DSCF6608-2.webp"/>
          </div>
      </div>
      <div className="third-home-slide" id="scrollTarget">
        <div className="home-concerts-container">
          <h2 className="home-concerts">Kommendes Konzert</h2>
          <div className="upcoming-concert-home-container">
              <div className="home-date-time-container">
                <p className="home-date-concert">30.07.2024 - 09.08.2024</p>
                {/* <p>17:00</p> */}
              </div>
              <h3 className="home-concert-name">Sommermusik im Oberen Nagoldtal</h3>
              <div className="home-place-concert-container">
                <p style={{marginBottom: "1.4rem"}}>Ort: Nagold, DE</p>
                <p>Tetiana Bielikova, Klavier</p>
              </div>
          </div>
          <a href="/de/kalender" className="biography-button-more"><span className="white-font">Kompletter Kalender</span></a>
        </div>
        <div className="home-bio-photo-container">
          <img className="main-first-photo" loading="lazy" src="../photos/DSCF6608.webp"/>
        </div>
      </div>
      < Footer />
    </div>
  );
};

export default HomeDE;
