import React, { useState, useEffect, useRef } from "react";

const ImageCarousel = ({ selectedImage, photos, onCloseClick }) => {
  const initialImageIndex = photos.findIndex((image) => image.id === selectedImage.id);
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex);
  const [touchStart, setTouchStart] = useState(null)
  const [carouselOpacity, setCarouselOpacity] = useState(1);
  const [touchEnd, setTouchEnd] = useState(null)
  const minSwipeDistance = 50 

  useEffect(() => {
    if (onCloseClick) {
      setCurrentImageIndex(initialImageIndex);
    }
  }, [onCloseClick, initialImageIndex]);

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  const handleLeftButtonClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? photos.length - 1 : prevIndex - 1));
  };

  const handleRightButtonClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === photos.length - 1 ? 0 : prevIndex + 1));
  };

  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => {
    const thumbnails = document.querySelector(".thumbnails");
    const thumbnailRect = thumbnails.getBoundingClientRect();
  
    if (
      e.targetTouches[0].clientX > thumbnailRect.left &&
      e.targetTouches[0].clientX < thumbnailRect.right &&
      e.targetTouches[0].clientY > thumbnailRect.top &&
      e.targetTouches[0].clientY < thumbnailRect.bottom
    ) {
      return;
    }
  
    setTouchEnd(e.targetTouches[0].clientX);
  };
  
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
  
    if (isLeftSwipe) {
      handleRightButtonClick();
    } else if (isRightSwipe) {
      handleLeftButtonClick();
    }
  };
  

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      handleLeftButtonClick();
    } else if (event.key === "ArrowRight") {
      handleRightButtonClick();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const selectedThumbnailRef = useRef(null);

  useEffect(() => {
    if (selectedThumbnailRef.current) {
      selectedThumbnailRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [currentImageIndex]);

  const renderedImages = photos.map((image, index) => (
    <img
      key={image.id}
      src={image.src}
      alt="Selected"
      className={`carousel-image ${index === currentImageIndex ? "visibleImage" : "hiddenImage"}`}
    />
  ));

  return (
    <div  onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd} 
      className={`custom-carousel ${carouselOpacity === 0 ? "fade-out" : ""}`}
      style={{ opacity: carouselOpacity }} >
      <button className="left-button" onClick={handleLeftButtonClick}>
        &#8592;
      </button>
      <button className="right-button" onClick={handleRightButtonClick}>
        &#8594;
      </button>
      <button className="close-button"
          onClick={() => {
            setCarouselOpacity(0);
            setTimeout(onCloseClick, 800);}}>
        &#x2716;
      </button>
      <div className="main-image">{renderedImages}</div>
      <a href="https://www.instagram.com/chernii.photo/" target="blank">
        <span className="photographer hover-links">@Alina_Chernii</span>
      </a>
      <div className="thumbnails">
        {photos.map((image, index) => (
          <div
            key={index}
            ref={index === currentImageIndex ? selectedThumbnailRef : null}
            className={`thumbnail ${index === currentImageIndex ? "selected" : ""}`}
            onClick={() => handleThumbnailClick(index)}
          >
            <img src={image.src} alt={`Thumbnail ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;