import React from "react";
 import YouTube, { YouTubePlayer } from "react-youtube";

 class YoutubeEmbed extends React.Component {
    render() {
    const { embedId, title } = this.props;

    const options = {
        height: 'auto',
        width: '430px',
        playerVars: {
          autoplay: 1,
          controls: 1,
        },
      };
      
      return (
        <div className="video-container">
          <YouTube videoId={embedId} options={options} onReady={this._onReady} id="video"/>
          <p className="video-name">{title}</p>
        </div>
      )

    }
  
    _onReady(event) {
      event.target.pauseVideo();
    }
  }

  export default YoutubeEmbed;
